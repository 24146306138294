import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"

import SEO from "../components/seo"
import Section from "../components/Section"
import { Container } from "../components/Container"
import BlockText from "../components/BlockText"

const Title = styled.h1`
  color: ${props => props.theme.primaryColors.primary};
  margin-bottom: 48px;
`

export const query = graphql`
  {
    sanityTermsPage {
      id
      _rawLocaleTitle
      _rawTextBlock1
    }
  }
`

const TermsPage = ({ data, pageContext }) => {
  const { locale } = pageContext
  const { _rawLocaleTitle, _rawTextBlock1 } = data.sanityTermsPage
  return (
    <>
      <SEO title={_rawLocaleTitle[locale]} />
      <Container paddingTop paddingBottom>
        <Section>
          <Grid container justify="center">
            <Grid item xs={12} md={8}>
              {_rawLocaleTitle && <Title>{_rawLocaleTitle[locale]}</Title>}
              {_rawTextBlock1 && <BlockText blocks={_rawTextBlock1[locale]} />}
            </Grid>
          </Grid>
        </Section>
      </Container>
    </>
  )
}

export default TermsPage
